/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 18:04:40
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 18:05:27
 */
import recordTable from './recordTable.vue'
export default recordTable
